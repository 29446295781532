import React from "react"
import {graphql} from "gatsby"
import {Col, Container, Row} from "react-bootstrap";
import {Header} from "../components/Header";
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header image={data.desktopImage.childImageSharp.gatsbyImageData} />
            <Container
                fluid={'lg'}
                as={'section'}
                className={"my-5 text-center text-md-left"}
            >
                <Row>
                    <Col md={{span: 3, offset: 1}}>
                        <div
                            className={'content content-left'}
                        >
                            <h2>
                                <span className={'bg-primary'}>Wijk in</span> bloei
                            </h2>
                            <p className={'font-weight-bold'}>
                                Kloosterveen is een nieuwe wijk ten westen van Assen en heeft alles wat je nodig hebt. Het biedt je de reuring van een grote stad dichtbij, maar geeft ook het rustige dorpse gevoel. Je komt te wonen in een wijk bestaande uit groene singels, smalle grachtjes en veel variatie aan woningen.
                            </p>
                            <p>
                                Het is een jonge en levendige wijk. Bewoners kunnen er naar hartelust een ommetje maken, stukje fietsen, genieten van het wild in en rond het water. Vanuit alle buurten zijn er veilige routes naar winkels, school en werk. Er zijn hier volop voorzieningen te vinden. Van zorginstellingen tot onderwijs en van supermarkten tot sport en recreatie. Werkelijk alles is er te vinden op loop- en fietsafstand. Het is een wijk kortom, waar je lekker kunt leven en genieten.
                            </p>
                        </div>
                    </Col>
                    <Col md={{span: 7, offset: 1}}>
                        <StaticImage
                            alt={"weide"}
                            aspectRatio={720/646}
                            src={"../img/sfeer/nieuw beeld pagina locatie en hoofdbeeld situatie en extra.jpg"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col
                        md={{span: 6, order: 2}}
                        className={'align-self-center'}
                    >
                        <div
                            className={'content content-right'}
                        >
                            <h2>
                                <span className={'bg-primary'}>Wonen</span> op een unieke locatie
                            </h2>
                            <p>
                                In heel Kloosterveen en in het bestaande Kloosterveste zijn en worden veel verschillende woningtypen gerealiseerd die aansluiten bij tal van uiteenlopende woonwensen. KADE 23 sluit hier op aan: wonen in een fraaie, ruime en duurzame woning, met een uitgesproken ontwerp, net even anders dan de buren en met een prachtig uitzicht over de Oostergracht. Dat en meer maakt KADE 23 een uniek woonproject.
                            </p>
                            <p>
                                En dat op een locatie waar je ‘s avonds even de stad in loopt voor een gezellig hapje eten, op zaterdagochtend verse broodjes haalt op de markt en in de middag even ongedwongen bijkletst met de buren, genietend van het groen om je heen.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={"koken"}
                            aspectRatio={702/507}
                            src={"../img/sfeer/mathilde-langevin-TLGUGAVUmL8-unsplash@2x.png"} />
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    markdownRemark: IPage
    desktopImage: ChildImageSharp
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "locatie"}}) {
            ...Page
        }
        desktopImage: file(relativePath: {eq: "sfeer/hoofdbeeld locatie en contact.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 4
                    transformOptions: {cropFocus: ENTROPY}
                    layout: FULL_WIDTH
                )
            }
        }
    }
`

